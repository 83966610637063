import React, {useEffect, useState} from 'react';
import {ContainerComponent} from '../core/components/container.component';
import {PAGE_CLUB_KEY, PAGE_HOME_KEY} from '../page/Main/CONSTANTS';
import {NextPage} from 'next';
import {MenuBuilderComponent} from '../page/Home/components/MenuBuilder/menuBuilder.component';
import {ClubMainComponent} from '../page/club';
import {ColumnComponent} from '../core/components/base/column.component';
import {TypedUseSelectorHook, useSelector} from 'react-redux';
import {RootState} from '../core/redux/store';
import {getAppLanguage, getAppVersion, saveAppLanguage, saveAppVersion} from '../core/services/localStorage.service';
import {MainService} from '../page/Main/main.service';
import {MenuDatabaseHelper} from '../page/Home/components/MenuBuilder/database/menu.database.helper';
import {useTranslation} from "react-i18next";
import {ENGLISH, ENGLISH_APP} from "../core/services/CONSTANTS";

const MainPage: NextPage = () => {
    const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
    const menuState = useAppSelector((state) => state.menuReducer);
    const [hasHead, setHasHead] = useState(false);
    const [menuBuilderState, setMenuBuilderState] = useState<string>();
    const mainService = new MainService();
    const {i18n} = useTranslation();

    useEffect(() => {
        let lang = i18n.language ?? ''
        if (`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() == ENGLISH_APP) {
            i18n.changeLanguage(ENGLISH);
            saveAppLanguage(ENGLISH);
            lang = ENGLISH
        }
        if (getAppLanguage() != lang) {
            getMenuBuilderData(lang);
        } else if (!hasHead) {
            mainService.getMenuBuilderPagesHead().subscribe((data) => {
                if (getAppVersion(i18n.language) !== data.headers['version']?.toString()) {
                    setHasHead(true);
                    saveAppVersion(i18n.language, data.headers['version'] ?? '');
                    getMenuBuilderData(lang);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (menuState.state) {
            setMenuBuilderState(menuState.state)
        }
    }, [menuState.state])

    function getMenuBuilderData(lang: string) {
        mainService.getMenuBuilderPages(lang).subscribe((data) => {
            if (data && data.data) {
                new MenuDatabaseHelper().addMenuItems(data.data);
            }
        });
    }

    return (
        <ContainerComponent>
            <ColumnComponent>
                <div className="grow">
                    {(`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() == ENGLISH_APP) ?
                        i18n.language == ENGLISH ? <MenuBuilderComponent page={PAGE_HOME_KEY}/> : null
                        :
                        menuBuilderState == PAGE_CLUB_KEY ? (
                            <ClubMainComponent/>
                        ) : menuBuilderState ? (
                            <MenuBuilderComponent page={menuBuilderState}/>
                        ) : null}
                </div>
            </ColumnComponent>
        </ContainerComponent>
    );
};

export default MainPage;
