import React, { FC, useRef, useState } from 'react';
import { ContainerComponent } from '../../core/components/container.component';
import { useTranslation } from 'react-i18next';
import CampaignComponent from '../../page/club/campaign/campaign.component';
import DiscountPage from '../../page/club/Discount/discountPage';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import MyClubComponent from '../../page/club/myclub/myClub.component';
import { ColumnComponent } from '../../core/components/base/column.component';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { Mission } from './mission/mission';

export const ClubMainComponent: FC = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('0');

  const handleChange = (event: React.ChangeEvent<any>, newValue: any) => {
    setTabValue(newValue);
    firstSwiper?.current?.slideTo(Number(newValue));
  };

  let firstSwiper = useRef<SwiperClass>();

  function getClubComponent() {
    switch (tabValue) {
      case '0':
        return <CampaignComponent />;
      case '1':
        return <DiscountPage />;
      case '2':
        return <Mission />;
      case '3':
        return <MyClubComponent />;
      default:
        return <CampaignComponent />;
    }
  }

  return (
    <ContainerComponent>
      <ColumnComponent className="clubBottom relative">
        <div className="clubTabContainer flex flex-row items-center px-3 w-full md:w-3/4 lg:w-1/2">
          <TabContext value={tabValue}>
            <TabList variant={'scrollable'} className="w-full" orientation={'horizontal'} onChange={handleChange}>
              <Tab className="text-base font p-1" label={t('clubText.campaigns')} value={'0'} />
              <Tab className="text-base font p-1" label={t('clubText.discounts')} value={'1'} />
              <Tab className="text-base font p-1" label={t('clubText.missions')} value={'2'} />
              <Tab className="text-base font p-1" label={t('clubText.myClub')} value={'3'} />
            </TabList>
          </TabContext>
        </div>
        <div className="w-full">
          <Swiper
            onSlideChange={(swiper) => setTabValue(swiper.activeIndex.toString())}
            onSwiper={(sw) => (firstSwiper.current = sw)}
          >
            {[...Array(3)].map((item, index) => (
              <TabPanel key={index} className="p-0" value={`${index}`}>
                <SwiperSlide key={index} virtualIndex={index}>
                  {getClubComponent()}
                </SwiperSlide>
              </TabPanel>
            ))}
          </Swiper>
        </div>
      </ColumnComponent>
    </ContainerComponent>
  );
};
